body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #333;

}


/*
COLORES
#333
#2c3e50

#1b3e6e
#305171

FOOTER
#145db2
#1a73e8
*/

/* --------------------------------------------------------- HEADER - NAV --------------------------------------------------------- */

header {
  background-color: #1b3e6e;
  padding: 15px;
  color: white;
}


nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

/* --------------------------------------------------------- HOME PAGE-FONDO --------------------------------------------------------- */

/* Home Page Section color #1a1b41 */
.home-page {
  font-family: 'Montserrat', sans-serif;
  background-color: #1b3e6e;
  color: white;
  position: relative;
  height: 625px;
}

.home-page::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 180px; /* Ajusta el tamaño según lo que necesites */
  background-color: #fff;
  clip-path: polygon(0 100%, 100% 0, 100% 100%);
}

/* NAV Styles color #1a1b41*/
.nav-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #1b3e6e;
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}




.navbar-brand h2 {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

.navbar-logo {
  width: 60px; /* Ajuste del tamaño de la imagen para que sea más grande */
  height: auto; /* Mantiene la proporción */
  margin-left: 60px;
  margin-right: 0.5px; /* Espacio entre la imagen y el título */
  margin-bottom: 10px;
  transition: transform 0.3s ease-in-out;
}

/* Efecto 3D de rebote */
.navbar-logo:hover {
  animation: bounce 0.5s ease-in-out;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(1); /* Escala normal */
  }
  50% {
    transform: scale(1.1); /* Escala ligeramente mayor */
  }
}

.navbar-title {
  color: white;
  font-size: 1.8rem; /* Ajusta el tamaño para mejor alineación */
  font-weight: bold;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: all 0.3s ease;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 30px;
}

.navbar-links li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  transition: transform 0.3s ease-in-out; /* Transición para el menú */
}

.navbar-links li a:hover {
  /* color: #6495ed; Hover color ff5a5f */
  text-decoration: underline;
  
}

.navbar-menu li a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  left: 0;
  bottom: 0;
  background-color: #000;
  transition: width 0.3s ease;
}



/* Estilos para móviles */
@media (max-width: 768px) {

 
  .navbar-logo {
    width: 90px; /* Ajuste del tamaño de la imagen para que sea más grande */
    height: auto; /* Mantiene la proporción */
    margin-left: 5px;
    margin-right: 0.5px; /* Espacio entre la imagen y el título */
    margin-bottom: 10px;
    transition: transform 0.3s ease-in-out;
  }

  .navbar-links {
    position: absolute;
    top: 70px; /* Ajustar para que coincida con el height del nav */
    right: 0;
    height: 100vh;
    width: 200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1b3e6e;
    transform: translateX(100%); /* Ocultar menú inicialmente */
  }

  .navbar-links.active {
    transform: translateX(0); /* Mostrar menú cuando está activo */
  }

  /* Ocultar el título del logo en móviles */
  .navbar-title {
    display: none;
  }

  /* Mostrar el botón de hamburguesa en pantallas pequeñas */
  .hamburger {
    display: flex;
  }

  .navbar-links li {
    margin: 1px 0;
  }
}

.cta-nav-button {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.cta-nav-button-1 {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.cta-nav-button:hover {
  background-color: #fff;
  color: #1b3e6e /*ff5a5f*/;
}

.cta-nav-button-1:hover {
  background-color: #fff;
  color: #1b3e6e /*ff5a5f*/;
}

/* Hero Section Styles */
.hero-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 120px 80px 80px;
  border-radius: 10px;

  
}

.hero-text {
  max-width: 50%;
  opacity: 0; /* Comienza invisible */
  transform: translateY(30px); /* Comienza desplazada hacia abajo */
  animation: slideUp 0.8s ease-in-out forwards; /* La animación se activa al cargar */
  transition: transform 0.3s ease-in-out;
}

.hero-text h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.hero-text p {
  font-size: 18px;
  color: #dcdcdc;
  margin-bottom: 30px;
  line-height: 1.6;
}

.cta-container {
  display: flex;
  align-items: center;
}

.email-input {
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  margin-right: 10px;
  background-color: #fff;
  color: #333;
}

.cta-button {
  background-color: #6495ed;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.cta-button:hover {
  background-color: #0a2447;
}



.hero-image {
  position: relative;
  z-index: 3; /* Asegura que la imagen esté por encima de otros elementos */
  /*max-width: 100%;*/
  
  height: auto;
  margin-top: -50px; /* Ajusta este valor para que la imagen se superponga más al fondo */
}

.hero-image img {
  padding-bottom: 40px;
  margin-left: 80px;
  width: 560px;
  height: 600px;
  /*transition: transform 0.3s ease-in-out;*/

  opacity: 0; /* Comienza invisible */
  transform: translateY(30px); /* Comienza desplazada hacia abajo */
  animation: slideUp 0.8s ease-in-out forwards; /* La animación se activa al cargar */
  transition: transform 0.3s ease-in-out;

  
}

/* Keyframes para la animación de abajo hacia arriba */
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(30px); /* Comienza más abajo */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Termina en su posición original */
  }
}


@media (max-width: 768px) {

  .home-page {
    height: 520px;
  }
  .home-page::after {
    
    height: 100px;
    

    /*
    clip-path: polygon(0 100%, 0 100%, 50% 100%);
    
    clip-path: polygon(0 100%, 0 100%, 50% 100%);
    clip-path: polygon(0 60%, 100% 60%, 50% 100%);

    clip-path: polygon(0 100%, 0 50%, 100% 100%);
    clip-path: polygon(0 100%, 100% 0, 100% 100%);*/
  }
  

  .hero-container {
    flex-direction: column;
    text-align: center;
    padding: 110px 20px;
  }

  .hero-text {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .hero-text h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  
  .hero-text p {
    font-size: 15px;
    color: #dcdcdc;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  

  .hero-image img {
    display: none;
  }

}



/* --------------------------------------------------------- STATICS CARDS --------------------------------------------------------- */

.statistics-section {
  padding: 50px 0;
  background-color: #ffffff;
  opacity: 0; /* Comienza invisible */
  transform: translateY(30px); /* Comienza desplazada hacia abajo */
  animation: slideUp 0.8s ease-in-out forwards; /* La animación se activa al cargar */
  transition: transform 0.3s ease-in-out;
  
}

.stat-card {
  background-color: #1b3e6e;
  /*background-color: #2c3e50;*/
  color: #ecf0f1;
  border-radius: 8px;
  padding: 15px;
  margin: 15px auto;
  text-align: center;
  width: 200px; /* Ancho reducido */
  height: 200px; /* Altura reducida */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stat-card:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  /*transform: translateY(-10px);*/
}

.stat-card i {
  font-size: 2.5rem; /* Tamaño del icono */
  margin-bottom: 10px;
}

.stat-card h3 {
  font-size: 1rem; /* Tamaño del texto */
  margin-top: 10px;
}

/* Ajuste para que las cards se alineen en una fila */
.statistics-section .container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}





/* --------------------------------------------------------- SOBRE NOSOTROS --------------------------------------------------------- */

.company-info-section {
  padding: 50px 0;
  background-color: #f9f9f9;
}

.company-info-section h2 {
  color: #1b3e6e;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 30px;
}

.company-info-section p {
  color: #333;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.company-info-section .btn-primary {
  background-color: #1b3e6e;
  border-color: #1b3e6e;
  font-size: 16px;
  padding: 10px 20px;
  margin-bottom: 10px;
}

.company-info-section .img-fluid {
  max-width: 100%;
  height: 1000hv;
  border-radius: 8px;
  
}

/*-----*/

.about-us-section {
  padding: 50px 0;
  background-color: #e3edf9; /* Fondo similar al de la imagen */
}

.about-us-section h2 {
  color: #1b3e6e; /* Color del título */
  font-size: 36px;
  font-weight: bold;
  margin-top: 160px;
  margin-bottom: 20px;
}

.about-us-section p {
  color: #1b3e6e; /* Color del texto */
  font-size: 16px;
}

.card {
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  height: auto; /* Ajusta el tamaño para que todas las tarjetas sean iguales */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Añadir transición para el efecto */
}

.card-body {
  display: flex;
  align-items: flex-start;
}

.card-body i {
  font-size: 40px;
  color: #1b3e6e;
  margin-right: 15px;
}

.card-body div {
  flex: 1;
}

.card-title {
  color: #1b3e6e; 
  font-weight: bold;
}

.card-text {
  color: #1b3e6e; 
  margin-top: 10px;
}

/* Efecto Hover */
.card:hover {
  transform: translateY(-10px); /* Mueve la tarjeta hacia arriba */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Aumenta la sombra para el efecto de elevación */
}

@media (max-width: 768px) {

  .about-us-section h2{
    margin-top: 5px;
    
  }
}




/* --------------------------------------------------------- SERVCIOS --------------------------------------------------------- */
.services-section {
  padding: 60px 20px;
  background-color: #f2f7fb;

  opacity: 0; /* Comienza invisible */
  transform: translateY(30px); /* Comienza desplazada hacia abajo */
  animation: slideUp 0.8s ease-in-out forwards; /* La animación se activa al cargar */
  transition: transform 0.3s ease-in-out;
}

.services-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.services-title {
  font-size: 2.5rem;
  color: #1b3e6e;
  margin-bottom: 40px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columnas */
  gap: 20px;
}

.service-card {
  background-color: white;
  padding: 30px;
 
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  position: relative;
  overflow: hidden;
}

.service-card img.service-icon {
  width: 100%; /* Ajuste para ocupar el ancho de la card */
  height: 150px; /* Altura fija para todas las imágenes */
  margin-bottom: 20px;
  object-fit: cover; /* Asegura que la imagen cubra el espacio sin deformarse */
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.service-card h1 {
  color: #1b3e6e;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.service-card p {
  color: #354052;
  font-size: 1rem;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  border: 1px solid #1b3e6e;
}

@media screen and (max-width: 1024px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr;
  }
}



/* --------------------------------------------------------- CLIENTES --------------------------------------------------------- */

#clients, #brands {
  background-color: #f2f7fb; /* Fondo claro  #eee*/
}

/* Estilos para la sección Clientes */
.clients-section {
  background-color: #f8f9fa;
  padding: 50px 0;
}
/* Estilos para la sección Clientes */
.clients-section {
  background-color: #f5f5f5; /* Fondo de la sección */
  padding: 50px 0;
}

.clients-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #1b3e6e; /* Color del título CLIENTES */
  font-weight: bold;

}

.client-logo-wrapper {
  background-color: #ffffff; /* Fondo blanco para las imágenes */
  border-radius: 15%; /* Bordes redondeados */
  padding: 20px; /* Espaciado interno alrededor de las imágenes */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para el efecto de elevación */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px; /* Altura fija para el contenedor */
  transition: transform 0.3s;
}

.client-logo-wrapper:hover {
  transform: scale(1.05); /* Efecto de aumento al pasar el cursor */
  
  border: 1px solid #1b3e6e;
}

.client-logo {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%; /* Forma circular */
  object-fit: contain; /* Asegura que la imagen se ajuste dentro del contenedor sin distorsión */
}
/**/

/* --------------------------------------------------------- MARCAS --------------------------------------------------------- */
.brands-section {
  text-align: center;
  padding: 50px 0;
  background-color: #f2f7fb;  /*#f0f0f0;*/
}

.brands-section h2{
  font-size: 2em;
  color: #1b3e6e;
  font-weight: bold;
}


.brands-slider {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.brands-track {
  display: flex;
  width: calc(250px * 10); /* 250px es el ancho de cada logo, 10 es la cantidad de logos */
  animation: scroll 25s linear infinite; /* Ajusta la duración para un desplazamiento suave */
}


.brand-logo {
  flex: 0 0 250px; /* Cada logo ocupa 250px de ancho */
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-logo img {
  width: 200px; /* Ajusta el tamaño de la imagen según necesites */
  height: auto;
}

/* Animación para el desplazamiento */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Se desplaza la mitad del ancho para crear un bucle */
  }
}



/*  ---------------------------------------------------------CONTACTO --------------------------------------------------------- */
.contact-section {
  padding: 80px 0;
  background-color: #f2f7fb; /* Color de fondo suave */
}

.section-title {
  font-size: 36px;
  color: #004085; /* Azul oscuro para el título */
  font-weight: bold;
  margin-bottom: 20px;
}

.section-description {
  font-size: 18px;
  color: #333;
  margin-bottom: 40px;
}

.contact-info {
  margin-bottom: 40px;
}

.col-md-7{
  width: 700px;
}

.contact-form {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: auto;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
}



.form-control {
  border: 1px solid #d1e9fe;
  border-radius: 5px;
  padding: 15px;
  font-size: 16px;
  color: #333;
  width: 100%; /* Asegura que los campos ocupen todo el ancho */
  transition: all 0.3s ease;
}

.form-control:focus {
  border-color: #004085;
  box-shadow: 0 0 10px rgba(0, 64, 133, 0.1);
}

.btn-primary {
  background-color: #004085;
  border-color: #004085;
  color: #fff;
  padding: 10px 30px;
  font-size: 18px;
  border-radius: 50px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-primary:hover {
  background-color: #0062cc;
  transform: translateY(-2px);
}







.contact-card {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 160px;
}

.contact-card .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.contact-card .fa-2x {
  color: #004a7c; /* Color azul oscuro */
  padding-left: 15px;
}

.contact-card .card-title {
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

.contact-card .card-text {
  color: #555;
  font-size: 14px;
}

@media (max-width: 768px) {
  .contact-section {
    padding: 50px 0;
  }

  .section-title {
    font-size: 30px;
  }

  .section-description {
    font-size: 16px;
  }

  .btn-primary {
    font-size: 16px;
    padding: 8px 25px;
  }
}






























